<template>
    <div class="select">
        <select :value="value" @input="$emit('input', $event.target.value)">
            <option value="">
                Bitte auswählen
            </option>
            <option :value="state.key" v-for="state in states" :key="state.key">
                {{ state.name }}
            </option>
        </select>
    </div>
</template>
<script>
import states from '../states';

export default {
    name: 'state-filter',
    props: {
        value: {},
    },
    computed: {
        states() {
            return states;
        }
    },
};
</script>
