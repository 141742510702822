const states = [
    {
        key: 'bw',
        name: 'Baden-Württemberg',
    },
    {
        key: 'by',
        name: 'Bayern',
    },
    {
        key: 'bb',
        name: 'Brandenburg',
        extra: 'Land',
    },
    {
        key: 'he',
        name: 'Hessen',
    },
    {
        key: 'mv',
        name: 'Mecklenburg-Vorpommern',
    },
    {
        key: 'ni',
        name: 'Niedersachsen',
    },
    {
        key: 'nw',
        name: 'Nordrhein-Westfalen',
    },
    {
        key: 'rp',
        name: 'Rheinland-Pfalz',
    },
    {
        key: 'sn',
        name: 'Sachsen',
    },
    {
        key: 'st',
        name: 'Sachsen-Anhalt',
    },
    {
        key: 'sh',
        name: 'Schleswig-Holstein',
    },
    {
        key: 'th',
        name: 'Thüringen',
    },
];

export default states;

export function getStateByKey(key) {
    return states.filter((state) => state.key === key)[0];
}
