<template>
    <div class="app" id="app">
        <div v-if="accepted">
            <div class="overlay">
                <div class="box-wrapper box-wrapper--select-filter">
                    <button class="icon-button icon-button--float"
                            :class="{['icon-button--search']: !showSelectFilter, ['icon-button--close']: showSelectFilter}"
                            @click.prevent="showSelectFilter = !showSelectFilter"></button>
                    <div class="box box--select-filter" v-show="showSelectFilter">
                        <label class="box__headline">
                            Bundesland
                            <state-filter v-model="selectedState" />
                        </label>
                        <label class="box__headline">
                            Jahr
                            <year-filter v-model="selectedYear" />
                        </label>
                    </div>
                </div>
            </div>

            <transition name="fade">
                <div class="loading" v-show="loading">
                    <div class="loading__content">
                        <div class="loading__icon"></div>
                        <p class="loading__desc">
                            Daten werden geladen ...
                        </p>
                    </div>
                </div>
            </transition>

            <GmapMap
                :center="center"
                :zoom="6"
                :options="mapOptions"
                ref="gmap">
                <GmapInfoWindow
                    :options="infoOptions"
                    :position="infoPosition"
                    :opened="infoOpened"
                    :content="infoContent"
                    @closeclick="infoOpened=false"></GmapInfoWindow>
                <GmapMarker v-for="(marker, index) in markers"
                            :key="`postal${index}`"
                            :position="{lat: Number(marker.lat), lng: Number(marker.lng)}"
                            @click="toggleInfo(marker, index)"
                            :icon="markerIconImage">
                </GmapMarker>
            </GmapMap>
        </div>
        <div v-else class="start-screen">
            <div class="start-screen__box">
                <h2 class="start-screen__title">
                    LSV Sortenergebnisse anzeigen
                </h2>
                <p class="start-screen__description">
                    Wir verwenden Google Maps zur Darstellung der LSV Sortenergebnisse.
                    Möchten Sie Google Maps nutzen, müssen hierfür erst die Inhalte aktiviert werden.
                    Für eine Aktivierung klicken Sie bitte auf die grüne Schaltfläche.
                    Erst dann werden personenbezogene Daten von Ihnen zum Google-Server übermittelt.
                    Bitte beachten Sie vor dem Aufrufen die Datenschutzhinweise von <a
                    href="https://policies.google.com/privacy?hl=de&gl=de" target="_blank">Google</a>.
                    Wir erlauben uns auch auf unsere Datenschutzerklärung zu verweisen.
                </p>
                <button class="start-screen__button" @click="acceptPolicy">Karte anzeigen</button>
            </div>
        </div>
    </div>
</template>

<script>
import {gmapApi} from 'vue2-google-maps';
import {mapActions, mapState} from 'vuex';
import {getStateByKey} from './states';

export default {
    name: 'App',
    data() {
        return {
            currentInfo: null,
            infoPosition: null,
            infoContent: null,
            infoOpened: false,
            infoCurrentKey: null,

            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: -17
                },
                content: '',
            },
            lastSelectedMarker: null,
            selectedState: null,
            selectedYear: null,
            accepted: false,
            loading: false,
            showSelectFilter: true,
            center: {lat: 51.0899841, lng: 5.9699475},
            mapOptions: {
                disableDefaultUI: false,
                fullscreenControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                styles: [{
                    "featureType": "all",
                    "elementType": "all",
                    "stylers": [{"visibility": "off"}]
                }, {
                    "featureType": "administrative",
                    "elementType": "geometry",
                    "stylers": [{"weight": "0.5"}, {"visibility": "on"}]
                }, {
                    "featureType": "administrative",
                    "elementType": "labels",
                    "stylers": [{"visibility": "simplified"}]
                }, {
                    "featureType": "administrative",
                    "elementType": "labels.text",
                    "stylers": [{"lightness": "-50"}, {"saturation": "-50"}]
                }, {
                    "featureType": "administrative.neighborhood",
                    "elementType": "labels.text",
                    "stylers": [{"hue": "#009aff"}, {"saturation": "25"}, {"lightness": "0"}, {"visibility": "simplified"}, {"gamma": "1"}]
                }, {
                    "featureType": "landscape",
                    "elementType": "geometry",
                    "stylers": [{"saturation": "0"}, {"lightness": "100"}, {"gamma": "2.31"}, {"visibility": "on"}]
                }, {
                    "featureType": "landscape",
                    "elementType": "labels",
                    "stylers": [{"visibility": "simplified"}, {"lightness": "20"}, {"gamma": "1"}]
                }, {
                    "featureType": "landscape",
                    "elementType": "labels.text.fill",
                    "stylers": [{"saturation": "-100"}, {"lightness": "-100"}]
                }, {
                    "featureType": "landscape",
                    "elementType": "labels.text.stroke",
                    "stylers": [{"visibility": "off"}]
                }, {
                    "featureType": "landscape.man_made",
                    "elementType": "all",
                    "stylers": [{"visibility": "simplified"}]
                }, {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [{"visibility": "off"}]
                }, {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [{"lightness": "0"}, {"saturation": "45"}, {"gamma": "4.24"}, {"visibility": "simplified"}, {"hue": "#00ff90"}]
                }, {
                    "featureType": "poi.park",
                    "elementType": "labels",
                    "stylers": [{"visibility": "off"}]
                }, {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [{"visibility": "on"}]
                }, {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [{"saturation": "-100"}, {"color": "#f5f5f5"}]
                }, {
                    "featureType": "road",
                    "elementType": "labels.text",
                    "stylers": [{"visibility": "simplified"}, {"color": "#666666"}]
                }, {
                    "featureType": "road",
                    "elementType": "labels.icon",
                    "stylers": [{"visibility": "off"}]
                }, {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [{"visibility": "off"}]
                }, {
                    "featureType": "road.arterial",
                    "elementType": "geometry.stroke",
                    "stylers": [{"visibility": "off"}]
                }, {
                    "featureType": "transit",
                    "elementType": "labels.icon",
                    "stylers": [{"saturation": "-25"}]
                }, {
                    "featureType": "transit.line",
                    "elementType": "all",
                    "stylers": [{"visibility": "simplified"}]
                }, {
                    "featureType": "transit.station.airport",
                    "elementType": "labels.icon",
                    "stylers": [{"visibility": "off"}]
                }, {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [{"visibility": "on"}]
                }, {
                    "featureType": "water",
                    "elementType": "geometry.fill",
                    "stylers": [{"lightness": "50"}, {"gamma": ".75"}, {"saturation": "100"}]
                }, {
                    "featureType": "water",
                    "elementType": "labels",
                    "stylers": [{"visibility": "simplified"}]
                }, {"featureType": "water", "elementType": "labels.icon", "stylers": [{"visibility": "off"}]}],
            },
        }
    },
    computed: {
        ...mapState(['markers']),
        google: gmapApi,
        markerIconImage() {
            return require(`./assets/map-marker.png`);
        },
    },
    methods: {
        ...mapActions(['init', 'loadYear']),
        async acceptPolicy() {
            this.loading = true;
            this.accepted = true;
            await this.init().then(async (init) => {
                this.selectedYear = init.selectedYear;
                this.loading = false;

                this.$refs.gmap.$mapPromise.then((map) => {
                    this.moveMapToAddress('Deutschland');
                })

            });
        },

        async moveMapToAddress(address) {
            const map = await this.$refs.gmap.$mapObject;
            const geocoder = new this.google.maps.Geocoder();
            geocoder.geocode({address: address}, (results) => {
                this.loading = false;
                map.setCenter(results[0].geometry.location);
                map.fitBounds(results[0].geometry.viewport);
            });
        },

        toggleInfo(marker, idx) {
            this.showSelectFilter = false;
            this.center = marker;
            this.infoPosition = marker;
            this.lastSelectedMarker = Object.assign({}, marker);

            this.infoOptions.content = `<div class="info__position">${marker.postal_code} ${marker.location}</div>`;
            this.infoOptions.content += `<p class="info__variety">${marker.variety}</p>`;

            this.infoOptions.content += `<div class="info__grain-wrapper">`;

            if (marker.grain_yield_1 || marker.grain_yield_3) {
                this.infoOptions.content += `
<div class="info__grain-yield">
    <div class="info__title">Stufe 1<br>Kornertrag</div>
    <div class="info__grain">
        <ul>`;

                if (marker.grain_yield_1) {
                    this.infoOptions.content += `
            <li><span>relativ</span><span>${marker.grain_yield_1}</span></li>`;
                }

                if (marker.grain_yield_3) {
                    this.infoOptions.content += `
            <li><span>dt/ha</span><span>${marker.grain_yield_3}</span></li>`;
                }
                this.infoOptions.content += `
        </ul>
    </div>
</div>`;
            }

            if (marker.grain_yield_2 || marker.grain_yield_4) {
                this.infoOptions.content += `
<div class="info__grain-yield">
    <div class="info__title">Stufe 2<br>Kornertrag</div>
    <div class="info__grain">
        <ul>`;

                if (marker.grain_yield_2) {
                    this.infoOptions.content += `
            <li><span>relativ</span><span>${marker.grain_yield_2}</span></li>`;
                }

                if (marker.grain_yield_4) {
                    this.infoOptions.content += `
            <li><span>dt/ha</span><span>${marker.grain_yield_4}</span></li>`;
                }
                this.infoOptions.content += `
        </ul>
    </div>
</div>`;
            }

            this.infoOptions.content += `</div>`;

            //check if its the same marker that was selected if yes toggle
            if (this.currentInfo === idx) {
                this.infoOpened = !this.infoOpened;
            }
            //if different marker set infowindow to open and reset current marker index
            else {
                this.infoOpened = true;
                this.currentInfo = idx;
            }
        },
    },
    watch: {
        async selectedState(value) {
            if (value === '') {
                this.moveMapToAddress('Deutschland');
            } else {
                if (getStateByKey(value).extra) {
                    this.moveMapToAddress(`${getStateByKey(value).name} ${getStateByKey(value).extra}`);
                } else {
                    this.moveMapToAddress(`${getStateByKey(value).name}`);
                }

            }
        },
        async selectedYear(value) {
            this.loading = true;
            this.loadYear(value).then(async (init) => {
                this.selectedYear = init.selectedYear;
                this.loading = false;
                if (this.lastSelectedMarker) {
                    const marker = this.markers.find((m) => m.lat === this.lastSelectedMarker.lat && m.lng === this.lastSelectedMarker.lng)
                    const markerIndex = this.markers.findIndex((m) => m.lat === this.lastSelectedMarker.lat && m.lng === this.lastSelectedMarker.lng)
                    if (marker && markerIndex && this.infoOpened === true) {
                        this.toggleInfo(marker, markerIndex);
                    } else {
                        this.infoOpened = false;
                    }
                }
            });
        },
    },
    filters: {
        lat(value) {
            let _str = value.toString();
            const _splittedStr = _str.split('.');
            if (_splittedStr.length >= 2) {
                _str = `${_splittedStr[0]}
            ° ${_splittedStr[1]}
            ′ N`;
            }
            return _str;
        },
        lng(value) {
            let _str = value.toString();
            const _splittedStr = _str.split('.');
            if (_splittedStr.length >= 2) {
                _str = `${_splittedStr[0]}
            ° ${_splittedStr[1]}
            ′ O`;
            }
            return _str;
        },
    },
}
</script>
