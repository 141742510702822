import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        availableYears: process.env.VUE_APP_AVAILABLE_YEARS.split(' '),
        markers: [],
    },
    mutations: {},
    actions: {
        async init({state, dispatch}) {
            const actualYear = state.availableYears[state.availableYears.length - 1];
            return await fetch(`/json/${actualYear}.json`)
                .then((resp) => {
                    return resp.json();
                })
                .then((data) => {
                    state.markers = data[Object.keys(data)[0]];
                    return {
                        selectedYear: actualYear,
                    }
                });
        },
        async loadYear({state, dispatch}, year) {
            return await fetch(`/json/${year}.json`)
                .then((resp) => {
                    return resp.json();
                })
                .then((data) => {
                    state.markers = [];
                    state.markers = data[Object.keys(data)[0]];
                    return {
                        selectedYear: year,
                    }
                });
        }
    },
    modules: {}
})
