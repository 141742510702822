<template>
    <div class="select">
        <select :value="value" @input="$emit('input', $event.target.value)">
            <option :value="year" v-for="year in availableYears" :key="year">
                {{ year }}
            </option>
        </select>
    </div>
</template>
<script>
import {mapState} from 'vuex';

export default {
    name: 'year-filter',
    props: {
        value: {},
    },
    computed: {
        ...mapState(['availableYears']),
    },
};
</script>
