import Vue from 'vue';
import App from './App.vue';
import store from './store';
import * as VueGoogleMaps from 'vue2-google-maps';

import StateFilter from './components/StateFilter.vue';
import YearFilter from './components/YearFilter.vue';

import './sass/app.scss';

Vue.component('state-filter', StateFilter);
Vue.component('year-filter', YearFilter);

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GMAP_API_KEY,
        libraries: 'places',
    },
    installComponents: true,
})

new Vue({
    store,
    render: h => h(App)
}).$mount('#app');
